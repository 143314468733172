var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.colSize } },
    [
      _vm.attr.max_length < 50 || _vm.attr.allow_multiple
        ? _c("v-text-field", {
            staticClass: "pt-6",
            attrs: {
              label: !_vm.attr.allow_multiple
                ? _vm.attr.name
                : _vm.attr.name + " Management",
              outlined: "",
              "background-color": "#fff",
              dense: "",
              autocomplete: "off",
              readonly: _vm.readonly,
              disabled: _vm.disabled,
              hint: _vm.disabledHint,
              "persistent-hint": _vm.disabled,
              rules: [_vm.isValidInput],
              counter: _vm.attr.max_length > 0 && !_vm.attr.allow_multiple
            },
            on: {
              change: _vm.save,
              click: _vm.checkValue,
              focus: _vm.checkTimeSensitive
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function() {
                    return [
                      _vm.attr.allow_multiple
                        ? _c("v-icon", [_vm._v(" mdi-dots-horizontal ")])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3283726482
            ),
            model: {
              value: _vm.newval,
              callback: function($$v) {
                _vm.newval = $$v
              },
              expression: "newval"
            }
          })
        : _c("v-textarea", {
            attrs: {
              label: _vm.attr.name,
              counter: _vm.attr.max_length > 0 && !_vm.attr.allow_multiple,
              readonly: _vm.readonly,
              disabled: _vm.disabled,
              hint: _vm.disabledHint,
              "persistent-hint": _vm.disabled,
              rules: [_vm.isValidInput],
              "no-resize": "",
              autocomplete: "off",
              rows: "3",
              outlined: "",
              dense: ""
            },
            on: {
              change: _vm.save,
              click: _vm.checkValue,
              focus: _vm.checkTimeSensitive
            },
            model: {
              value: _vm.newval,
              callback: function($$v) {
                _vm.newval = $$v
              },
              expression: "newval"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { userAccess } from '@/mixins/user-access'
export const attrsMixin = {
  props: [
    'attr',
    'attrvalue',
    'attrvalues',
    'noFocus',
    'readonly',
    'disabled',
    'disabledHint'
  ],
  watch: {
    attrvalue: {
      handler (newValue) {
        if (newValue == null) {
          this.newval = null
        } else {
          this.newval = this.isKeyValue
            ? newValue.attr_key_value
            : newValue.value
        }
      },
      deep: true
    }
  },
  mixins: [userAccess],
  created () {
    if (this.attrvalue) {
      this.newval = this.isKeyValue
        ? this.attrvalue.attr_key_value
        : this.attrvalue.value
    }
  },
  computed: {
    isKeyValue () {
      return this.attr.data_type.constant === 'KEY_VALUE'
    },
    errorMessages () {
      if (!this.hasWriteAccess) return []
      else return (this.attr.is_required && !this.newval && this.attrvalues.length === 0) ? `${this.attr.name} is required.` : []
    }
  },
  methods: {
    checkValue () {
      if (this.attr.allow_multiple && typeof this.noFocus === 'undefined') {
        this.$emit('multi-value')
      }
    },
    checkTimeSensitive () {
      if (this.attr.is_time_sensitive && typeof this.noFocus === 'undefined') {
        this.$emit('time-sensitive')
      }
    },
    save (val) {
      this.$emit('save-attr', val)
    }
  }
}

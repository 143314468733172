var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      staticClass: "mt-6",
      attrs: { cols: typeof _vm.noFocus === "undefined" ? 7 : null }
    },
    [
      _vm.attr.allow_multiple
        ? _c("v-autocomplete", {
            attrs: {
              label: _vm.attr.name,
              items: _vm.keyValues,
              required: _vm.attr.is_required,
              "error-messages": _vm.errorMessages,
              "item-text": "name",
              "item-value": "id",
              multiple: "",
              chips: "",
              "small-chips": "",
              outlined: "",
              dense: "",
              "return-object": "",
              hint: _vm.disabledHint,
              "persistent-hint": _vm.disabled,
              readonly: _vm.readonly,
              disabled: _vm.disabled
            },
            on: { change: _vm.saveValues },
            model: {
              value: _vm.newValues,
              callback: function($$v) {
                _vm.newValues = $$v
              },
              expression: "newValues"
            }
          })
        : _c("v-autocomplete", {
            attrs: {
              value: _vm.attrvalue !== null ? _vm.attrvalue.attr_key_value : "",
              label: _vm.attr.name,
              items: _vm.keyValues,
              required: _vm.attr.is_required,
              "error-messages": _vm.errorMessages,
              "item-text": "name",
              "item-value": "id",
              "background-color": "#fff",
              outlined: "",
              dense: "",
              hint: _vm.disabledHint,
              "persistent-hint": _vm.disabled,
              readonly: _vm.readonly,
              disabled: _vm.disabled,
              "return-object": ""
            },
            on: { focus: _vm.checkTimeSensitive, change: _vm.save },
            model: {
              value: _vm.newval,
              callback: function($$v) {
                _vm.newval = $$v
              },
              expression: "newval"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }